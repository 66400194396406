import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/chat.png";
import emotion from "../../Assets/Projects/cv.png";
import editor from "../../Assets/Projects/pdf.png";
import chatify from "../../Assets/Projects/game.png";
import suicide from "../../Assets/Projects/ball.png";
import bitsOfCode from "../../Assets/Projects/mac.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently I upload that secret source later.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="No Game Cl"
              description="This website allow us to play game in web"
              ghLink="https://github.com/Abinpsy"
              demoLink="https://game.0ab.in"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="Mac os in web"
              description="I made a mac os web version. also you can install that"
              ghLink="https://github.com/Abinpsy"
              demoLink="https://mac.0ab.in"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="PDF editor online"
              description="this will create code to PDF interface"
              ghLink="https://github.com/Abinpsy"
              demoLink="https://pdf.0ab.in"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title="AI chat"
              description="made for my friend sparrow."
              ghLink="https://github.com/Abinpsy"
              demoLink="https://chat.0ab.in"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide}
              isBlog={false}
              title="Ball game"
              description="my old project. ha ha ha so funny these days. think about old..."
              ghLink="https://github.com/Abinpsy"
              demoLink="https://ball.0ab.in"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="CV-MAKER"
              description="Build Your Resume With This Free & Awesome Resume Builder...."
              ghLink="https://github.com/Abinpsy"
              demoLink="https://cv.0ab.in" 
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
