import React, { useState, useEffect } from 'react';
import './TypingAnimation.css'; // Import the CSS file

const TypingAnimation = () => {
  const [text, setText] = useState('abin');
  const [showCursor, setShowCursor] = useState(true);

  useEffect(() => {
    const timer = setInterval(() => {
      if (text === 'abin') {
        setText('ab.in');
      } else if (text === 'ab.in') {
        setText('0ab.in');
      } else {
        setText('abin');
      }
    }, 1000); // Change text every 1 second

    const cursorTimer = setInterval(() => {
      setShowCursor(show => !show); // Toggle the cursor state
    }, 500); // Blink the cursor every 0.5 second

    return () => {
      clearInterval(timer); // Cleanup on unmount
      clearInterval(cursorTimer);
    };
  }, [text]);

  return (
    <h1>
      {text}
      <span className={`cursor ${showCursor ? 'blink' : ''}`}>|</span>
    </h1>
  );
};

export default TypingAnimation;
